<!--
 * @Author: your name
 * @Date: 2022-04-13 09:28:37
 * @LastEditTime: 2024-01-16 16:33:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \cloud-diagnos\src\mobile\pages\Home.vue
-->
<!--  -->
<template>
  <div id="home">
    <Header></Header>
  </div>
</template>
    
<script>
import Header from '../../components/Header.vue'
export default {
  name:'',
  props: {
     
  },
  components: {
     Header
  },
  data () {
    return {
    
    }
  },
  created () {
     
  },
  mounted () {
     
  },
  watch: {
     
  },
  methods: {
     
  }
}
</script>
    
<style lang="scss">
  #home {

  }
</style>