<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-16 16:29:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-16 16:34:03
 * @FilePath: \official_website\src\mobile\components\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="header">
    <div class="header-box">

    </div>
  </div>
</template>
    
<script>
export default {
  name:'',
  props: {
     
  },
  components: {
     
  },
  data () {
    return {
    
    }
  },
  created () {
     
  },
  mounted () {
     
  },
  watch: {
     
  },
  methods: {
     
  }
}
</script>
    
<style lang="scss">
#header {
  .header-box {
    width: 100%;
    height: 3.75rem;
    background-color: #ccc;
  }
}   
</style>